import { ready } from "@nephele/dom-utils";
import { ActionFormHelper, ValidateForm } from "@nephele/form-utils";
import { PhoneInput } from "@nephele/phone-input";
import { CaptchaStatus, turnstileCallback } from "@nephele/turnstile-captcha/captcha-validation";
import { insertCaptcha } from "@nephele/turnstile-captcha/captcha-loader";

declare global {
    interface Window {
        turnstileCallback: () => void;
        preferSelfHostedCaptcha: boolean;
    }
}

ready(async () => {
    const do_not_check_status = [CaptchaStatus.Disabled, CaptchaStatus.Passed, CaptchaStatus.NotSupported];
    let status: CaptchaStatus = CaptchaStatus.Disabled;

    window.turnstileCallback = () => {
        const el = document.querySelector('#turnstile-widget')! as HTMLDivElement;
        return turnstileCallback(el)
    };

    if (window.turnstileConfig) {
        const isCaptchaLoaded = await insertCaptcha('turnstileCallback');
        if (!isCaptchaLoaded) {
            console.error('Captcha failed to load');
        }
    }

    const emailRegex = /\S+@\S+\.\S+/;
    const localPhoneRegex = /^(?!\+)(0?)([1-9][0-9]{5,})/;
    const iddPhoneRegex = /^(?!\+)00([1-9][0-9]{9,})/;

    const username = document.querySelector("#usernameInput") as HTMLInputElement;
    const dummyPhoneInput = document.querySelector("#actionForm [data-phone-input]") as HTMLInputElement;
    const captcha = document.getElementById("turnstile-widget") as HTMLDivElement;

    const phoneInput = PhoneInput(dummyPhoneInput, {});


    const validate: ValidateForm<Record<string, string>> = (utility, errors) => {
        utility.clearValidationErrors();

        if (!username.value) {
            if (errors) {
                utility.setValidationError(username, errors.UsernameFormatError!);
            }
            return false;
        }

        const isPossibleEmail = emailRegex.test(username.value.trim());

        const isPossibleLocalNumber = localPhoneRegex.test(username.value.trim());
        const isPossibleNumberWithIdd = iddPhoneRegex.test(username.value.trim());
        const isPossibleNumberWithPlusSign = username.value.trim().charAt(0) === "+" &&
            phoneInput.isValidNumber(username.value.trim(), phoneInput.getSelectedCountryData()?.iso2);

        if (!isPossibleEmail && !isPossibleLocalNumber && !isPossibleNumberWithIdd && !isPossibleNumberWithPlusSign) {
            if (errors) {
                utility.setValidationError(username, errors.UsernameFormatError!);
            }

            return false;
        }

        if (captcha) {
            const response = window.turnstile?.getResponse();
            if (response === undefined && status === CaptchaStatus.WaitingInteraction) {
                if (errors) {
                    utility.setValidationError(captcha, errors.CaptchaValidationError!);
                }

                return false;
            }

            if (status === CaptchaStatus.Failed) {
                return false;
            }
        }
        
        if (!do_not_check_status.includes(status)) {
            return false;
        }

        return true;
    };

    const actionFormHelper = new ActionFormHelper(
        document.querySelector("#actionForm")! as HTMLFormElement,
        document.querySelector("#submitButton")! as HTMLButtonElement,
        document.querySelector("#loader")! as HTMLElement,
        window.errors,
        username,
        validate,
        null,
        "div.error > span"
    );

    document.addEventListener("CaptchaStatus", (event: Event) => {
        const e = event as CustomEvent<CaptchaStatus>;
        status = e.detail;
        actionFormHelper.checkSubmitButtonState(username);
    });
});
