import { useState, useRef, useEffect } from "preact/hooks";
import { Fragment } from "preact/jsx-runtime";
import { detectInputAutoFill, classNames } from "../utils";

export function PasswordToggle() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [iconHidden, setIconHidden] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const handler = () => {
            setIconHidden(inputRef.current?.value.length === 0);
        };
        handler();

        const unsubscribe = detectInputAutoFill(inputRef.current!, (val) => {
            setIconHidden(!val);
        });

        inputRef.current?.addEventListener("input", handler);
        return () => {
            inputRef.current?.removeEventListener("input", handler);
            unsubscribe();
        };
    }, []);

    return (
        <Fragment>
            <i
                role="button"
                tabIndex={0}
                ref={(el) => {
                    inputRef.current = el?.parentElement?.querySelector("input") ?? null;
                }}
                className={classNames("icon", {
                    "icon-eye": !passwordVisible,
                    "icon-eye-off": passwordVisible,
                })}
                hidden={iconHidden}
                onClick={() => {
                    setPasswordVisible((val) => {
                        const newVal = !val;
                        if (inputRef.current) {
                            inputRef.current.type = !newVal ? "password" : "text";
                        }
                        return newVal;
                    });
                } }
            ></i>
        </Fragment>
    );
}
