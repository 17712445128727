export function detectInputAutoFill(
  input: HTMLInputElement,
  cb: (autofilled: boolean) => void,
) {
  const abortController = new AbortController();
  input.addEventListener(
    "animationstart",
    (e) => {
      cb(e.animationName === "onAutoFillStart");
    },
    {
      signal: abortController.signal,
    },
  );
  // check if animation is already running
  // this is necessary because the animationstart event is not fired if the animation is already running
  setTimeout(() => {
    const style = window.getComputedStyle(input);
    cb(style.animationName === "onAutoFillStart");
  }, 16);

  return () => {
    abortController.abort();
  }
}

export function classNames(...args: unknown[]): string {
  return args
    .flatMap((arg) => {
      if (typeof arg === "string") return arg;
      if (Array.isArray(arg)) return arg;
      if (typeof arg === "object" && arg !== null) {
        return Object.entries(arg)
          .filter(([_, value]) => Boolean(value))
          .map(([key]) => key);
      }
      return null;
    })
    .filter(Boolean)
    .join(" ");
}
